import mapImages from '@/util/mapImages';
import Util from '@/util';
import BaseStore from './baseStore';
import store from '../../../store';

const ol = window.ol;

class BusStore extends BaseStore {
  constructor(map, source) {
    super(map, source);
    this.jumps = 50;
    this.hoveredId = null;
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      name: 'STRING',
    };
    this.counterMoves = {};
    this.busesCounter = {
      busOnlineCount: 0,
      busOfflineCount: 0,
    };
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([item.longitude, item.latitude])),
      name: item.id,
      population: 4000,
      rainfall: 500,
    });
    item.typeOfItem = 'buses';
    marker.setId('buses__' + item.id);
    marker.set('record', item);
    this.markers.set(item.id, marker);
    if (item.status === 'up') {
      this.busesCounter.busOnlineCount++;
    } else {
      this.busesCounter.busOfflineCount++;
    }
    this._updateMarker(item);
    return marker;
  }

  async smoothMove(marker, record, item) {
    if (!this.counterMoves[item.id]) {
      this.counterMoves[item.id] = 0;
    }

    this.counterMoves[item.id] += 1;

    const deltaLatitude = (item.latitude - record.latitude) / this.jumps;
    const deltaLongitude = (item.longitude - record.longitude) / this.jumps;

    let tmpLatitude = record.latitude;
    let tmpLongitude = record.longitude;

    const control = this.counterMoves[item.id];

    for (
      let index = 0;
      !marker.get('clustered')
      && this.isMakerOnDisplay(marker)
      && index < this.jumps
      && control === this.counterMoves[item.id];
      index++
    ) {
      tmpLatitude += deltaLatitude;
      tmpLongitude += deltaLongitude;
      marker.getGeometry().setCoordinates(ol.proj.fromLonLat([tmpLongitude, tmpLatitude]));
      // eslint-disable-next-line
      await this._sleep(150);
    }

    record.latitude = item.latitude;
    record.longitude = item.longitude;

    if (control === this.counterMoves[item.id]) {
      marker.getGeometry().setCoordinates(ol.proj.fromLonLat([record.longitude, record.latitude]));
    }
  }

  async _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id);
    item.statusTitle = Util.statusMap[item.status];
    tmpMarker.setStyle(this._getStyle(item));
    const record = tmpMarker.get('record');
    if (record.status === 'up' && item.status === 'down') {
      this.busesCounter.busOnlineCount--;
      this.busesCounter.busOfflineCount++;
    }
    if (record.status === 'down' && item.status !== 'down') {
      this.busesCounter.busOnlineCount++;
      this.busesCounter.busOfflineCount--;
    }

    if (store.state.map.show_buses && (record.latitude !== item.latitude || record.longitude !== item.longitude)) {
      // Remove smooth move for performance
      const extent = this.map.mapView.calculateExtent();
      const coord = ol.proj.fromLonLat([item.longitude, item.latitude]);
      const zoom = this.map.mapView.getZoom()
      if (ol.extent.containsCoordinate(extent, coord) && zoom >= 13) {
        this.smoothMove(tmpMarker, record, item);
      } else {
        record.latitude = item.latitude;
        record.longitude = item.longitude;
        tmpMarker.getGeometry().setCoordinates(ol.proj.fromLonLat([record.longitude, record.latitude]));
      }
    }

    const visible = this._isVisible(item);

    Object.assign(record, item);
    this.notify(item);
    this._updateVisbility(tmpMarker, visible);
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item);
    }
    this._updateMarker(item);
    return null;
  }

  _getStyle(item) {
    const scale = 1;
    let icon = mapImages.bus[this.map.mapMode].down;
    if (item.category === 'bus') {
      if (item.status === 'up') {
        icon = mapImages.bus[this.map.mapMode].up;
        if (item.selected) {
          icon = mapImages.bus[this.map.mapMode].up_selected;
        }
      } else if (item.selected) {
        icon = mapImages.bus[this.map.mapMode].down_selected;
      }
    } else if (item.status === 'up') {
      icon = mapImages.taxi[this.map.mapMode].up;
      if (item.selected) {
        icon = mapImages.taxi[this.map.mapMode].up_selected;
      }
    } else if (item.selected) {
      icon = mapImages.taxi[this.map.mapMode].down_selected;
    }

    return this._getIcon(icon, item.name, {
      scale,
      offsetY: mapImages.bus.offsetY,
      offsetX: mapImages.bus.offsetX,
    });
  }

  _getStyleSelected(item) {
    const scale = 1;
    let icon = mapImages.bus[this.map.mapMode].down_selected;
    if (item.status === 'up') {
      icon = mapImages.bus[this.map.mapMode].up_selected;
    }
    if (item.category === 'taxi') {
      icon = mapImages.taxi[this.map.mapMode].down_selected;
      if (item.status === 'up') {
        icon = mapImages.taxi[this.map.mapMode].up_selected;
      }
    }

    return this._getIcon(icon, item.name, {
      scale,
      offsetY: mapImages.bus.offsetY,
      offsetX: mapImages.bus.offsetX,
    });
  }

  async updateMarkersStyle() {
    // eslint-disable-next-line
    for (const marker of this.markers.values()) {
      marker.setStyle(this._getStyle(marker.get('record')));
    }
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId);
      marker.setStyle(this._getStyle(marker.get('record')));
      this.hoveredId = null;
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id);
      marker.setStyle(this._getStyleSelected(marker.get('record')));
      this.hoveredId = id;
    }
  }
}
export default BusStore;
