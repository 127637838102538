<template>
  <div
    ref="windowElement"
    class="window"
    :style="{
      display: 'initial',
      width: width + 'px',
      height: height + 40 + 'px',
      left: left + 'px',
      top: top + 'px',
      bottom: bottom
    }"
  >
    <div
      ref="windowHeader"
      class="window-header"
    >
      <p class="windowTitle">
        <span v-html="title"></span>
      </p>
      <div class="close-button">
        <v-btn
          v-for="(action, key) in actions"
          :key="key"
          @click="action.action"
          icon
          dark
          small
        >
          <v-icon dark>
            {{ action.icon }}
          </v-icon>
        </v-btn>
        <v-tooltip bottom>
          <v-btn
            v-if="!fullScreen"
            @click="setFullScreen"
            icon
            dark
            small
          >
            <v-icon dark>
              mdi-fullscreen
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            @click="setBackScreen"
            icon
            dark
            small
          >
            <v-icon dark>
              mdi-fullscreen-exit
            </v-icon>
          </v-btn>
          <span v-if="!fullScreen">PANTALLA COMPLETA</span>
          <span v-else>SALIR DE PANTALLA COMPLETA</span>
        </v-tooltip>
        <v-btn
          @click="close"
          icon
          dark
          small
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div
      class="mainWindow"
      :style="{
        height: 'calc(100% - 40px)'
      }"
    >
      <slot></slot>
    </div>
    <div v-for="resizer in resizers" :ref="resizer" :class="'resizer ' + resizer" :key="resizer"></div>
  </div>
</template>

<script>
export default {
  name: 'Window',
  props: {
    title: String,
    width: {
      type: Number,
      default: 480
    },
    height: {
      type: Number,
      default: 320
    },
    minWidth: {
      type: Number,
      default: 240
    },
    minHeight: {
      type: Number,
      default: 200
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    bottom: String,
    center: {
      type: Boolean,
      default: false
    },
    onClose: Function,
    closeAction: Function,
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      window: null,
      resizers: ['resizer-ne', 'resizer-se', 'resizer-sw', 'resizer-nw'],
      fullScreen: false,
      lastSize: {}
    };
  },
  mounted() {
    this.window = this.$refs.windowElement;
    this.window.onmousedown = this.activeWindow;
    this.makeDraggable();
    this.makeResizable();
  },
  methods: {
    close() {
      if (this.onClose) {
        this.onClose()
      }
      if (this.closeAction) {
        this.closeAction()
      } else {
        this.$destroy()
        this.$el.parentNode.removeChild(this.$el);
      }
    },
    setFullScreen() {
      this.fullScreen = true;
      this.lastSize = {
        top: this.window.style.top,
        left: this.window.style.left,
        width: this.window.style.width,
        height: this.window.style.height
      }
      this.window.style.top = '0'
      this.window.style.left = '0'
      this.window.style.width = '100%'
      this.window.style.height = '100%'
    },
    setBackScreen() {
      this.fullScreen = false;
      this.window.style.top = this.lastSize.top
      this.window.style.left = this.lastSize.left
      this.window.style.width = this.lastSize.width
      this.window.style.height = this.lastSize.height
    },
    makeDraggable() {
      let pos1 = 0
      let pos2 = 0
      let pos3 = 0
      let pos4 = 0;
      // if ('ontouchstart' in document.documentElement) {
      let pos1touch = 0
      let pos2touch = 0
      let pos3touch = 0
      let pos4touch = 0
      // }
      const elementDrag = (e) => {
        e.preventDefault();
        if ('ontouchstart' in document.documentElement) {
          pos1touch = pos3touch - e.touches[0].clientX;
          pos2touch = pos4touch - e.touches[0].clientY;
          pos3touch = e.touches[0].clientX;
          pos4touch = e.touches[0].clientY;
          if (this.window.offsetTop - pos2touch >= 0) {
            this.window.style.top = (this.window.offsetTop - pos2touch) + 'px';
          }
          if (this.window.offsetLeft - pos1touch >= 0) {
            this.window.style.left = (this.window.offsetLeft - pos1touch) + 'px';
          }
        } else {
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          if (this.window.offsetTop - pos2 >= 0) {
            this.window.style.top = (this.window.offsetTop - pos2) + 'px';
          }
          if (this.window.offsetLeft - pos1 >= 0) {
            this.window.style.left = (this.window.offsetLeft - pos1) + 'px';
          }
        }
      }

      const closeDragElement = () => {
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
      }
      const dragMouseDown = (e) => {
        if (!('ontouchstart' in document.documentElement)) {
          e.preventDefault();
        }
        pos3 = e.clientX;
        pos4 = e.clientY;
        if ('ontouchstart' in document.documentElement) {
          try {
            pos3touch = e.touches[0].clientX;
            pos4touch = e.touches[0].clientY;
          } catch (error) {
            console.error(error);
          }
        }
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
        document.ontouchend = closeDragElement;
        document.ontouchmove = elementDrag;
        this.activeWindow();
      }
      if (this.$refs.windowHeader) {
        this.$refs.windowHeader.onmousedown = dragMouseDown;
        this.$refs.windowHeader.ontouchstart = dragMouseDown;
      }
    },
    activeWindow() {
      const active = document.getElementsByClassName('window');
      for (let i = active.length - 1; i > -1; i--) {
        active[i].classList.remove('windowActive');
        this.window.className += ' windowActive';
      }
    },
    makeResizable() {
      let startX
      let startY
      let startWidth
      let startHeight
      this.resizers.forEach((resizer) => {
        const doDrag = (e) => {
          this.fullScreen = false;
          const resizers = {
            'resizer-ne': () => {
              if (startWidth + e.clientX - startX >= this.minWidth) {
                this.window.style.width = (startWidth + e.clientX - startX) + 'px';
              }
              if (startHeight - e.clientY + startY >= this.minHeight) {
                this.window.style.height = (startHeight - e.clientY + startY) + 'px';
                this.window.style.top = e.clientY + 'px';
              }
            },
            'resizer-se': () => {
              if (startWidth + e.clientX - startX >= this.minWidth) {
                this.window.style.width = (startWidth + e.clientX - startX) + 'px';
              }
              if (startHeight + e.clientY - startY >= this.minHeight) {
                this.window.style.height = (startHeight + e.clientY - startY) + 'px';
              }
            },
            'resizer-sw': () => {
              if (startWidth - e.clientX + startX >= this.minWidth) {
                this.window.style.width = (startWidth - e.clientX + startX) + 'px';
                this.window.style.left = e.clientX + 'px';
              }
              if (startHeight + e.clientY - startY >= this.minHeight) {
                this.window.style.height = (startHeight + e.clientY - startY) + 'px';
              }
            },
            'resizer-nw': () => {
              if (startWidth - e.clientX + startX >= this.minWidth) {
                this.window.style.width = (startWidth - e.clientX + startX) + 'px';
                this.window.style.left = e.clientX + 'px';
              }
              if (startHeight - e.clientY + startY >= this.minHeight) {
                this.window.style.height = (startHeight - e.clientY + startY) + 'px';
                this.window.style.top = e.clientY + 'px';
              }
            }
          }
          resizers[resizer]()
        }
        const stopDrag = (e) => {
          e.preventDefault()
          document.documentElement.removeEventListener('mousemove', doDrag, false);
          document.documentElement.removeEventListener('mouseup', stopDrag, false);
        }
        const initDrag = (e) => {
          e.preventDefault()
          startX = e.clientX;
          startY = e.clientY;
          startWidth = parseInt(document.defaultView.getComputedStyle(this.window).width, 10);
          startHeight = parseInt(document.defaultView.getComputedStyle(this.window).height, 10);
          document.documentElement.addEventListener('mousemove', doDrag, false);
          document.documentElement.addEventListener('mouseup', stopDrag, false);
        }
        const init = () => {
          this.window.removeEventListener('click', init, false)
          this.$refs[resizer][0].addEventListener('mousedown', initDrag, false);
        };
        init()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.window {
  position: absolute;
  top: 80px;
  z-index: 9;
  background-color: black;
  border-radius: 8px 8px 0 0;
  box-shadow: 8px 8px 6px -6px black;
  opacity: 1;
  display: none;
}

.window-header {
  padding: 10px;
  z-index: 10;
  background-color: rgb(48 67 97);
  color: #fff;
  border-radius: 4px 4px 0 0;
  height: 40px;
  justify-content: space-between;
  display: flex;
  touch-action: none;
  cursor: move;
}

.close-button {
  position: relative;
  bottom: 2px;
  white-space: nowrap;
}

.windowActive {
  z-index: 100;
}

.windowTitle {
  position: relative;
  bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 20px;
}

.mainWindow {
  width: 100%;
  overflow: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.resizer {
  width: 10px;
  height: 10px;
  position: absolute;
  &.resizer-ne {
    right: 0;
    top: 0;
    cursor: ne-resize;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
  }
  &.resizer-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
  }
  &.resizer-sw {
    left: -5px;
    bottom: -5px;
    cursor: sw-resize;
  }
  &.resizer-nw {
    left: -5px;
    top: -5px;
    cursor: nw-resize;
  }
}
</style>
