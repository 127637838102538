<template>
  <transition name="slide-fade">
    <v-btn-toggle
      v-model="toggleMultiple"
      active-class="black"
      dense
      multiple
      class="map-actions"
      v-hotkey="keymap"
      v-show="show"
      dark
    >
      <v-tooltip v-for="(action, index) in actions" :key="index" top>
        <template v-slot:activator="{ on }">
          <v-menu
            v-if="action.actions"
            open-on-hover
            top
            offset-y
            :close-on-content-click="false"
            dark
            min-width="180px"
          >
            <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
              <v-btn
                x-large
                style="background-color: #5f6360"
                v-bind="menuAttrs"
                v-on="menuOn"
                @click="action.action"
              >
                <v-icon v-html="action.icon" color="white"></v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, itemIndex) in action.actions"
                :key="'it-' + index + '-' + itemIndex"
              >
                <template>
                  <v-list-item-title>
                    <v-checkbox
                      :input-value="item.active"
                      @change="item.action"
                      color="primary"
                      :label="item.title"
                      hide-details
                    >
                    </v-checkbox>
                  </v-list-item-title>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn v-else x-large @click="action.action" style="background-color: #5f6360" v-on="on">
            <v-icon v-html="action.icon" color="white"></v-icon>
          </v-btn>
        </template>
        <span>{{ action.help }}</span>
      </v-tooltip>
    </v-btn-toggle>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {},
  mounted() {
    this.actions = [
      {
        icon: '$camera-white',
        active: this.getShowCameras,
        help: 'CAMARAS',
        action: () => {
          window.VMA.mapComponent.camerasLayer.setVisible(!this.getShowCameras);
          this.setShowCameras(!this.getShowCameras);
        },
      },
      {
        icon: '$bus-white',
        active: this.getShowBuses,
        help: 'TRANSPORTE',
        action: () => {
          window.VMA.mapComponent.busesLayer.setVisible(!this.getShowBuses);
          this.setShowBuses(!this.getShowBuses);
        },
      },
      {
        icon: '$lpr-white',
        active: this.getShowLprs,
        help: 'ANALITICOS',
        action: () => {
          window.VMA.mapComponent.lprsLayer.setVisible(!this.getShowLprs);
          this.setShowLprs(!this.getShowLprs);
        },
      },
      {
        icon: '$device-white',
        active: this.getShowDevices,
        help: 'UNIDADES',
        action: () => {
          window.VMA.mapComponent.devicesLayer.setVisible(!this.getShowDevices);
          this.setShowDevices(!this.getShowDevices);
        },
      },
      {
        icon: '$sensor-white',
        active: this.getShowSensors,
        help: 'BOTONES DE PANICO',
        action: () => {
          window.VMA.mapComponent.sensorLayer.setVisible(!this.getShowSensors);
          window.VMA.mapComponent.externalSensorLayer.setVisible(!this.getShowSensors);
          this.setShowSensors(!this.getShowSensors);
        },
      },
      {
        icon: 'mdi-alert-outline',
        help: 'INCIDENTES',
        active: this.getShowIncidentsGroup,
        action: () => {
          window.VMA.mapComponent.incidentsLayer.setVisible(
            !this.getShowIncidentsGroup && this.getShowIncidents
          );
          window.VMA.mapComponent.incidentsHeatMapLayer.setVisible(
            !this.getShowIncidentsGroup && this.getShowIncidentsHeatMap
          );
          this.setShowIncidentsGroup(!this.getShowIncidentsGroup);
        },
        actions: [
          {
            title: 'INCIDENTES',
            active: this.getShowIncidents,
            action: () => {
              window.VMA.mapComponent.incidentsLayer.setVisible(!this.getShowIncidents);
              this.setShowIncidents(!this.getShowIncidents);
            },
          },
          {
            title: 'MAPA DE CALOR',
            active: this.getShowIncidentsHeatMap,
            action: () => {
              window.VMA.mapComponent.incidentsHeatMapLayer.setVisible(
                !this.getShowIncidentsHeatMap
              );
              this.setShowIncidentsHeatMap(!this.getShowIncidentsHeatMap);
            },
          },
        ],
      },
      {
        id: 'geofences',
        icon: '$geofence-white',
        active: this.getShowGeofences,
        help: 'GEOCERCAS',
        action: () => {
          console.log('GEOCERCAS', !this.getShowGeofences || this.getShowMandoUnico);
          window.VMA.mapComponent.geofencesLayer.setVisible(!this.getShowGeofences || this.getShowMandoUnico);
          this.setShowGeofences(!this.getShowGeofences);

          const features = window.VMA.mapComponent.geofencesLayer.getSource().getFeatures()
          features.forEach(element => {
            const record = element.get('record')
            if (record.attributes.mandounico === '1') {
              element.set('visible', !!this.getShowMandoUnico)
            } else {
              element.set('visible', !!this.getShowGeofences)
            }
          });
        },
      },
      {
        id: 'mandounico',
        icon: 'mdi-bookmark-check',
        active: this.getShowMandoUnico,
        help: 'MANDO UNICO',
        action: () => {
          console.log('MANDO UNICO', this.getShowGeofences || !this.getShowMandoUnico);
          window.VMA.mapComponent.geofencesLayer.setVisible(this.getShowGeofences || !this.getShowMandoUnico);
          this.setShowMandoUnico(!this.getShowMandoUnico);

          const features = window.VMA.mapComponent.geofencesLayer.getSource().getFeatures()
          features.forEach(element => {
            const record = element.get('record')
            if (record.attributes.mandounico === '1') {
              element.set('visible', !!this.getShowMandoUnico)
            } else {
              element.set('visible', !!this.getShowGeofences)
            }
          });
        },
      },
      {
        icon: 'mdi-weather-hazy',
        help: 'CLIMA',
        active: this.getShowClima,
        action: () => {
          window.VMA.mapComponent.presasLayer.setVisible(!this.getShowClima && this.getShowPresas);
          window.VMA.mapComponent.sismosLayer.setVisible(!this.getShowClima && this.getShowSismos);
          window.VMA.mapComponent.firesLayer.setVisible(!this.getShowClima && this.getShowFires);
          this.setShowClima(!this.getShowClima);
        },
        actions: [
          {
            title: 'PRESAS',
            active: this.getShowPresas,
            action: () => {
              window.VMA.mapComponent.presasLayer.setVisible(!this.getShowPresas);
              this.setShowPresas(!this.getShowPresas);
            },
          },
          {
            title: 'SISMOS',
            active: this.getShowSismos,
            action: () => {
              window.VMA.mapComponent.sismosLayer.setVisible(!this.getShowSismos);
              this.setShowSismos(!this.getShowSismos);
            },
          },
          {
            title: 'INCENDIOS',
            active: this.getShowFires,
            action: () => {
              window.VMA.mapComponent.firesLayer.setVisible(!this.getShowFires);
              this.setShowFires(!this.getShowFires);
            },
          },
        ],
      },
    ];
    this.actions.forEach((action, index) => {
      if (action.active) {
        this.toggleMultiple.push(index);
      }
    });
  },
  data() {
    return {
      show: true,
      toggleMultiple: [],
      actions: [],
    };
  },
  methods: {
    ...mapActions('map', [
      'setShowCameras',
      'setShowBuses',
      'setShowLprs',
      'setShowDevices',
      'setShowSensors',
      'setShowIncidentsGroup',
      'setShowIncidents',
      'setShowIncidentsHeatMap',
      'setShowGeofences',
      'setShowMandoUnico',
      'setShowPresas',
      'setShowSismos',
      'setShowFires',
      'setShowClima',
    ]),
    toggle() {
      this.show = !this.show;
    },
  },
  watch: {
    getShowGeofences(val) {
      const index = this.actions.findIndex((a) => a.id === 'geofences');
      if (!val && this.toggleMultiple.includes(index)) {
        const index2 = this.toggleMultiple.findIndex((a) => a === index);
        this.toggleMultiple.splice(index2, 1);
      }
      if (val && !this.toggleMultiple.includes(index)) {
        this.toggleMultiple.push(index);
      }
    },
    getShowMandoUnico(val) {
      const index = this.actions.findIndex((a) => a.id === 'mandounico');
      if (!val && this.toggleMultiple.includes(index)) {
        const index2 = this.toggleMultiple.findIndex((a) => a === index);
        this.toggleMultiple.splice(index2, 1);
      }
      if (val && !this.toggleMultiple.includes(index)) {
        this.toggleMultiple.push(index);
      }
    },
  },
  computed: {
    ...mapGetters('map', [
      'getShowCameras',
      'getShowBuses',
      'getShowLprs',
      'getShowDevices',
      'getShowSensors',
      'getShowIncidentsGroup',
      'getShowIncidents',
      'getShowIncidentsHeatMap',
      'getShowGeofences',
      'getShowMandoUnico',
      'getShowPresas',
      'getShowSismos',
      'getShowFires',
      'getShowClima',
    ]),
    keymap() {
      return {
        'ctrl+space': this.toggle,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.map-actions {
  position: absolute;
  bottom: var(--map-widget-padding);
  opacity: 0.8;
  left: 50%;
  transform: translateX(-50%);
  border: solid;
}
/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateY(20px);
  opacity: 0;
}
</style>
