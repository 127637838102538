import IncidentMovementEnd from '@/assets/map/incident_movement_end.svg';
import BaseStore from './baseStore';

const ol = window.ol;

class ReportantStore extends BaseStore {
  constructor(map, source) {
    super(map, source);
    this.hoveredId = null;
    this.visibleConditionRules = {
      systemId: 'STRING',
    };
    this.cleanMarkers();
    setInterval(() => {
      this.cleanMarkers();
    }, 10000);
  }

  cleanMarkers() {
    const now = new Date().getTime();
    const keys = this.markers.keys();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      const marker = this.markers.get(key);
      const item = marker.get('record');
      const diff = now - parseInt(item?.lastPosition?.additionalData?.call_start_time || '0');
      if (diff >= 20 * 60 * 1000) {
        this.markers.delete(key);
        this.olSource.removeFeature(marker);
        if (this.visibleItems.indexOf(item) > -1) {
          const index = this.visibleItems.indexOf(item);
          this.visibleItems.splice(index, 1);
        }
      }
    }
  }

  _createMarker(item) {
    const location = item.positions[item.positions.length - 1];
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(
        window.ol.proj.fromLonLat([location.longitude, location.latitude])
      ),
      name: item.additionalData.phone,
      population: 4000,
      rainfall: 500,
    });
    marker.setId('reportant__' + item.additionalData.phone);
    marker.set('record', item);
    this.markers.set(item.additionalData.phone, marker);

    this._updateMarker(item);
    return marker;
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.additionalData.phone);
    tmpMarker.setStyle(this._getStyle(item));
    const record = tmpMarker.get('record');
    const location = item.positions[item.positions.length - 1];
    const oldLocation = record.positions[record.positions.length - 1];
    if (
      location.latitude !== oldLocation.latitude
      || location.longitude !== oldLocation.longitude
    ) {
      tmpMarker
        .getGeometry()
        .setCoordinates(window.ol.proj.fromLonLat([location.longitude, location.latitude]));
    }
    const visible = this._isVisible(item);
    Object.assign(record, item);
    this.notify(item);
    this._updateVisbility(tmpMarker, visible);
  }

  updateMarker(item) {
    item.id = item.additionalData.phone;
    if (!this.markers.get(item.additionalData.phone)) {
      return this._createMarker(item);
    }
    this._updateMarker(item);
    return null;
  }

  _getStyle(item) {
    return this._getIcon(IncidentMovementEnd, item.additionalData.phone, {
      offsetY: 0,
      offsetX: 3,
    });
  }

  _getStyleSelected(item) {
    return this._getIcon(IncidentMovementEnd, item.additionalData.phone, {
      offsetY: 0,
      offsetX: 3,
    });
  }

  async updateMarkersStyle() {
    if (!this.markers) return;
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')));
    });
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId);
      marker.setStyle(this._getStyle(marker.get('record')));
      this.hoveredId = null;
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id);
      marker.setStyle(this._getStyleSelected(marker.get('record')));
      this.hoveredId = id;
    }
  }
}

export default ReportantStore;
