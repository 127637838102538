import Util from '@/util'
import BaseStore from './baseStore'

const ol = window.ol

class IncidentHeatMapStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.visibleCondition = { status: ['ACTIVO', 'HISTORICO'] }

    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      category: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      'attributes.prioridad': 'LIST'
    }
  }

  _createMarker(item, weight = 10) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(ol.proj.fromLonLat([item.longitude, item.latitude])),
      weight,
    });
    marker.setId('incidents__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)
    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    const record = tmpMarker.get('record')
    const visible = this._isVisible(item)
    Object.assign(record, item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    delete item.fullSearch
    item.fullSearch = this.getFullSearch(item)
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    this._updateMarker(item)
    return null
  }

  removeFeatureById(id) {
    const marker = this.markers.get(id)
    if (marker) {
      const record = marker.get('record')
      if (record) {
        this._updateVisbility(marker, false)
        this.markers.delete(id)
      }
    }
  }
}

export default IncidentHeatMapStore
