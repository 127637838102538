import SISMO from '@/assets/katrina/SISMO.png';
import Util from '@/util'
import BaseStore from './baseStore'

const ol = window.ol

class SismoStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.hoveredId = null
    this.visibleConditionRules = {
    }
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ])),
      name: item.id
    });
    item.typeOfItem = 'sismo'
    marker.setId('sismo__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)
    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker.getGeometry().setCoordinates(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ]))
    }
    const visible = this._isVisible(item)

    Object.assign(record, item)
    this.notify(item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    this._updateMarker(item)
    return null
  }

  _getStyle(item) {
    const label = item.name.length > 20 ? item.name.slice(0, 18) + '...' : item.name
    return this._getIcon(SISMO, label, {
      scale: 0.3,
      offsetX: 5,
      offsetY: -30,
    })
  }

  _getStyleSelected(item) {
    this._getStyle(item)
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      marker.setStyle(this._getStyle(marker.get('record')))
      this.hoveredId = null
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')))
      this.hoveredId = id
    }
  }

  async updateMarkersStyle() {
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }
}

export default SismoStore
