<template>
  <div class="hidebutton" v-if="!show">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          style="
            color: rgb(255 255 255 / 70%) !important;
            background-color: rgb(20, 20, 20, 0.7);
            pointer-events: auto;
          "
          icon
          x-large
          @click="toggle"
          v-on="on"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span>INFORMACIÓN</span>
    </v-tooltip>
  </div>
  <map-card title="Información" :actions="getActions" v-else>
    <template v-slot:body>
      <div v-if="getSelectedItem != undefined" style="height: 100%">
        <v-simple-table dense class="desc-table" dark>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, key) in getSelectedItem.data" :key="key">
                <td
                  v-if="item.type === 'title'"
                  v-html="item.label"
                  style="text-align: center; padding-top: 5px"
                  colspan="2"
                ></td>
                <td
                  v-else-if="item.type === 'titleValue'"
                  v-html="item.value"
                  class="text-uppercase font-weight-bold"
                  style="text-align: center; padding-top: 5px; background-color: #303e54"
                  colspan="2"
                ></td>
                <td
                  v-else-if="item.type === 'photo'"
                  v-html="item.value"
                  style="text-align: center; padding-top: 5px"
                  colspan="2"
                ></td>
                <template v-else-if="item.type === 'template'"></template>
                <template v-else-if="item.type === 'geofencedata'">
                  <td>
                    <v-row>
                      <v-col cols="9">
                        <grafica-incidentes
                          v-bind:summary="prueba(item.value)"
                          v-bind:key="JSON.stringify(item)"
                        />
                      </v-col>
                      <v-col cols="3" style="text-align: center">
                        <v-row>
                          <v-col cols="12" style="padding-top: 20px; padding-left: 5px; padding-right: 5px; padding-bottom: 0px;">
                            <div>
                              <v-icon> {{ '$camera-relieve' }} </v-icon>
                            </div>
                            <div>
                              {{ item.value.cameras.summary.up }} /
                              {{ item.value.cameras.summary.total }}
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding-left: 5px; padding-right: 5px; padding-bottom: 0px; padding-top: 9px;">
                            <div>
                              <v-icon> {{ '$device-relieve' }} </v-icon>
                            </div>
                            <div>
                              {{ item.value.devices.summary.online }} /
                              {{ item.value.devices.summary.total }}
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding-left: 5px; padding-right: 5px; padding-bottom: 0px; padding-top: 9px;">
                            <div>
                              <v-icon> {{ '$sensor-relieve' }} </v-icon>
                            </div>
                            <div>
                              {{ item.value.sensors.summary.up }} /
                              {{ item.value.sensors.summary.total }}
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding-left: 5px; padding-right: 5px; padding-bottom: 0px; padding-top: 9px;">
                            <div>
                              <v-icon> {{ '$bus-relieve' }} </v-icon>
                            </div>
                            <div>
                              {{ item.value.buses.summary.up }} /
                              {{ item.value.buses.summary.total }}
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding-left: 5px; padding-right: 5px; padding-bottom: 0px; padding-top: 9px;">
                            <div>
                              <v-icon> {{ '$lpr-relieve' }} </v-icon>
                            </div>
                            <div>
                              {{ item.value.lprs.summary.up }} /
                              {{ item.value.lprs.summary.total }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center" colspan="2">INCIDENTES RELEVANTES DE LAS ULTIMAS 24 HORAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(motivo, index) in motivos" :key="index">
                            <td v-if="getCategories(item)[motivo] > 0">
                              <v-icon :color=" getCategories(item)[motivo]<=10?  'orange darken-2': 'red darken-2'">{{ motivos_icon[motivo] }}</v-icon>
                              <b> - </b>
                              <b>{{ motivo }}</b>
                            </td>
                            <td
                              v-if="getCategories(item)[motivo] > 0"
                              col="2"
                              style="text-align: right"
                            >
                              {{ getCategories(item)[motivo] }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <br />
                    <v-row>
                      <v-col
                        cols="12"
                        style="text-align: justify"
                        class="text-caption"
                      >
                        <b>FUENTE:</b> Servicio de Atención de Llamadas de Emergencia 911, conforme
                        al Catálogo Nacional de Incidentes de Emergencias ver. 3.0, expedido por el
                        Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública.
                      </v-col>
                    </v-row>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <b>{{ item.label }}</b>
                  </td>
                  <td v-html="item.value"></td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </template>
  </map-card>
</template>
<script>
export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue'),
    'grafica-incidentes': () => import('@/components/map/GraficaIncidentes.vue'),
  },
  data() {
    return {
      show: true,
      motivos_icon: {
        'DISPAROS DE ARMA DE FUEGO': 'mdi-bullet',
        'ENFRENTAMIENTO DE GRUPOS ARMADOS': 'mdi-pistol',
        'FUGA DE REOS': 'mdi-account-arrow-right',
        HOMICIDIOS: 'mdi-account-remove',
        'LESIONES POR ARMA DE FUEGO': 'mdi-account-alert',
        'PERSONAS FALLECIDAS POR ARMA': 'mdi-account-minus',
        'PORTACIÓN DE ARMA DE FUEGO': 'mdi-pistol',
        'PRIVACIÓN DE LA LIBERTAD': 'mdi-account-off',
        'ROBO A CASA HABITACIÓN': 'mdi-home',
        'ROBO A COMERCIO': 'mdi-store',
        'ROBO A TRANSEÚNTE': 'mdi-walk',
        'ROBO A VEHÍCULO': 'mdi-car',
        'ROBO A TRANSPORTE PÚBLICO': 'mdi-bus',
        'ROBO DE VEHÍCULO': 'mdi-car-off',
      },
      motivos: [
        'DISPAROS DE ARMA DE FUEGO',
        'ENFRENTAMIENTO DE GRUPOS ARMADOS',
        'FUGA DE REOS',
        'HOMICIDIOS',
        'LESIONES POR ARMA DE FUEGO',
        'PERSONAS FALLECIDAS POR ARMA',
        'PORTACIÓN DE ARMA DE FUEGO',
        'PRIVACIÓN DE LA LIBERTAD',
        'ROBO A CASA HABITACIÓN',
        'ROBO A COMERCIO',
        'ROBO A TRANSEÚNTE',
        'ROBO A VEHÍCULO',
        'ROBO A TRANSPORTE PÚBLICO',
        'ROBO DE VEHÍCULO',
      ],
      motivos_r: {
        'ALLANAMIENTO DE MORADA': 'ROBO A CASA HABITACIÓN',
        'DETONACIÓN DE ARMA DE FUEGO EN ESCUELA': 'DISPAROS DE ARMA DE FUEGO',
        'DETONACIÓN POR ARMA DE FUEGO': 'DISPAROS DE ARMA DE FUEGO',
        'ENFRENTAMIENTO DE GRUPOS ARMADOS': 'ENFRENTAMIENTO DE GRUPOS ARMADOS',
        'FUGA DE REOS': 'FUGA DE REOS',
        HOMICIDIO: 'HOMICIDIOS',
        'PERSONA LESIONADA POR PROYECTIL DE ARMA DE FUEGO': 'LESIONES POR ARMA DE FUEGO',
        'PERSONAS FALLECIDAS POR ARMA BLANCA': 'PERSONAS FALLECIDAS POR ARMA',
        'PERSONAS FALLECIDAS POR PROYECTIL DE ARMA DE FUEGO': 'PERSONAS FALECIDAS POR ARMA',
        'PORTACIÓN DE ARMAS DE FUEGO O CARTUCHOS': 'PORTACIÓN DE ARMA DE FUEGO',
        'PRIVACIÓN DE LA LIBERTAD': 'PRIVACIÓN DE LA LIBERTAD',
        'ROBO A BANCO CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A BANCO SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A CASA DE CAMBIO CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A CASA DE CAMBIO SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A CASA HABITACIÓN CON VIOLENCIA': 'ROBO A CASA HABITACIÓN',
        'ROBO A CASA HABITACIÓN SIN VIOLENCIA': 'ROBO A CASA HABITACIÓN',
        'ROBO A EMPRESA DE TRASLADO DE VALORES CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A EMPRESA DE TRASLADO DE VALORES SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A FERROCARRIL': 'ROBO A VEHÍCULO',
        'ROBO A GASOLINERA CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A GASOLINERA SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A NEGOCIO CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A NEGOCIO SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A REPARTIDOR CON VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO A REPARTIDOR SIN VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO A TEMPLO RELIGIOSO CON VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A TEMPLO RELIGIOSO SIN VIOLENCIA': 'ROBO A COMERCIO',
        'ROBO A TRANSEÚNTE CON VIOLENCIA': 'ROBO A TRANSEÚNTE',
        'ROBO A TRANSEÚNTE SIN VIOLENCIA': 'ROBO A TRANSEÚNTE',
        'ROBO A TRANSPORTE ESCOLAR CON VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO A TRANSPORTE ESCOLAR SIN VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL CON VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO A TRANSPORTISTA O VEHÍCULO COMERCIAL SIN VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO CON VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR': 'ROBO A VEHÍCULO',
        'ROBO DE MOTOCICLETA CON VIOLENCIA': 'ROBO DE VEHÍCULO',
        'ROBO DE MOTOCICLETA SIN VIOLENCIA': 'ROBO DE VEHÍCULO',
        'ROBO DE VEHÍCULO PARTICULAR CON VIOLENCIA': 'ROBO DE VEHÍCULO',
        'ROBO DE VEHÍCULO PARTICULAR SIN VIOLENCIA': 'ROBO DE VEHÍCULO',
        'ROBO EN CARRETERA CON VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO EN CARRETERA SIN VIOLENCIA': 'ROBO A VEHÍCULO',
        'ROBO EN TRANSPORTE COLECTIVO PÚBLICO O PRIVADO CON VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO EN TRANSPORTE COLECTIVO PÚBLICO O PRIVADO SIN VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO EN TRANSPORTE PÚBLICO INDIVIDUAL CON VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO EN TRANSPORTE PÚBLICO INDIVIDUAL SIN VIOLENCIA': 'ROBO A TRANSPORTE PÚBLICO',
        'ROBO SIN VIOLENCIA A CONDUCTOR DE VEHÍCULO PARTICULAR': 'ROBO A VEHÍCULO',
      },
      actions: [
        {
          icon: 'mdi-content-copy',
          help: 'Copiar',
          condition: () => this.getSelectedItem,
          action: () => {
            const text = this.getSelectedItem.data
              .map((item) => {
                if (item.label === 'FOTO') {
                  return '';
                }
                return `${item.label}: ${item.value}`;
              })
              .join('\n');
            this.copyToClipboard(text);
          },
        },
        {
          icon: 'mdi-crosshairs-gps',
          help: 'Centrar',
          condition: () => this.getSelectedItem,
          action: () => {
            const data = window.VMA.mapComponent.selectedItem;
            this.$parent.$emit('center-alert', data);
          },
        },
        {
          icon: 'mdi-triangle-outline mdi-rotate-90',
          help: 'Ocultar',
          action: this.toggle,
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    prueba(x) {
      console.log(x);
      const r = {
        total: x.incidents.summary.total,
        seguridad: x.incidents.summary.seguridad,
        medico: x.incidents.summary.medico,
        proteccion_civil: x.incidents.summary.proteccion_civil,
        servicios_publicos: x.incidents.summary.servicios_publicos,
      };
      return r;
    },
    getCategories(item) {
      const categoriesKeys = Object.keys(item.value.incidents.categories);
      const categories = {};

      categoriesKeys.forEach((key) => {
        const motivo = this.motivos_r[key];
        if (motivo) {
          if (!categories[motivo]) {
            categories[motivo] = 0;
          }
          categories[motivo] += item.value.incidents.categories[key];
        }
      });
      return categories;
    },
    copyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        window.navigator.clipboard.writeText(text);
        console.error("Error al copiar texto", err);
      }
      document.body.removeChild(textArea);
    }
  },
  computed: {
    getSelectedItem() {
      // eslint-disable-next-line
      this.show = true;
      return this.$store.getters['map/getSelectedItem'];
    },
    getActions() {
      return [
        ...(this.getSelectedItem?.actions?.filter((action) => {
          return action.condition ? action.condition(this.getSelectedItem) : true;
        }) || []),
        ...this.actions.filter((action) => {
          return action.condition ? action.condition(this.getSelectedItem) : true;
        }),
      ];
      // (this.getSelectedItem && this.getSelectedItem.actions ? this.getSelectedItem.actions : []).concat(this.actions)
    },
  },
  watch: {
    getSelectedItem(val) {
      if (val) {
        this.show = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-card-alert {
  border-bottom: 1px solid;
}

.hidebutton {
  width: 100%;
  text-align: right;
  padding: 4px, 16px;
}

.desc-card-header {
  width: 100%;
  height: 30px;
  background-color: black;
}

.desc-title-card-header {
  height: 30px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
}

.no-selected-item {
  font-size: 36px;
  height: 350px;
  width: 100%;
}

.desc-table {
  height: 100%;
  overflow: auto;
}

/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px rgba (0, 0, 0, 0.2), 0 2px 2px 0 rgba (0, 0, 0, 0.14),
    0 1px 5px 0 rgba (0, 0, 0, 0.12) !important;
}
</style>
