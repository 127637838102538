import axios from 'axios';
import { Utility } from './utility';

const MurphyApiService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/murphy/placa/',
  timeout: 50000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJ3aGF0c2FwcEBtdXJwaHkubGF0IiwiaWF0IjoxNzIyMTkxNjc1LCJleHAiOjE3NTM3Mjc2NzV9.HvcMvJvrIsVOVKXD-vlr9YGzdBawtw7K6AGGduHeDn4`,
  },
  paramsSerializer: (params) => {
    const parts = [];

    const encode = (val) => {
      return encodeURIComponent(val)
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, '+')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']');
    };

    const convertPart = (key, val) => {
      if (val instanceof Date) {
        // eslint-disable-next-line no-param-reassign
        val = val.toISOString();
      } else if (val instanceof Object) {
        // eslint-disable-next-line no-param-reassign
        val = JSON.stringify(val);
      }
      parts.push(encode(key) + '=' + encode(val));
    };

    Object.entries(params).forEach(([key, val]) => {
      if (val === null || typeof val === 'undefined') {
        return;
      }
      if (Array.isArray(val)) {
        val.forEach((v, i) => convertPart(`${key}`, v));
      } else {
        convertPart(key, val);
      }
    });
    return parts.join('&');
  },
});

const err = (error) => {
  const { status } = error.response;
  switch (status) {
    case 401:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 403:
      window.VMA.$emit('ACESS_DENIED');
      break;
    case 500:
      window.VMA.$emit('SERVER_ERROR');
      break;
    default:
      break;
  }
  return Promise.reject(error);
};

// request interceptor
MurphyApiService.interceptors.request.use((config) => {
  return config;
}, err);

// response interceptor
MurphyApiService.interceptors.response.use(({ data }) => data, err);

export class MurphyService {
  constructor() {
    this._axios = axios;
    if (typeof MurphyService.instance === 'object') {
      return MurphyService.instance;
    }
    this.utility = new Utility();
    MurphyService.instance = this;
    return this;
  }

  async getBaseType(name, identifier) {
    const result = { code: 200, queryObjects: [], description: '' };
    let baseType = {};
    try {
      const response = await MurphyApiService.get('/base-type/identifiers', {
        params: {
          name,
          identifier,
        },
      });
      baseType = response;
      if (!baseType || baseType.id < 1) {
        result.code = 403;
        result.description = 'USUARIO NO AUTORIZADO';
      }
    } catch (erra) {
      console.log(erra);
      if (MurphyApiService.isAxiosError(erra)) {
        if (erra.response?.status === 400) {
          result.code = 403;
          result.description = 'USUARIO NO AUTORIZADO';
        }
      }
      if (result.code === 200) {
        result.code = 500;
        result.description = 'ERROR AL PROCESAR LA SOLICITUD';
      }
    }
    return { result, baseType };
  }

  async callCreateQuery(params, result) {
    try {
      const response = await MurphyApiService.post('/query-base', params, {
        timeout: 120000,
      });
      return response;
    } catch (erra) {
      if (axios.isAxiosError(erra)) {
        if (erra.response?.status === 403) {
          result.code = 403;
          result.description = 'USUARIO INVALIDO';
        }
        if (erra.response?.status === 400) {
          result.code = 400;
          result.description = 'FORMATO INVALIDO';
        }
        console.log(erra.message);
      }
      if (result.code === 200) {
        result.code = 500;
        result.description = 'ERROR AL PROCESAR LA SOLICITUD';
      }
    }
    return null;
  }

  async createQuery(name, identifier, params) {
    const { result, baseType } = await this.getBaseType(name, identifier);

    if (baseType && baseType.id) {
      const parameters = {
        identifier,
        baseObjectId: baseType.id,
        parameters: baseType.requiredParameters,
      };

      parameters.parameters.forEach((param) => {
        if (param.customizable) {
          if (params[param.name]) {
            param.value = params[param.name];
            if (param.type === 'string') {
              param.value = param.value.toUpperCase().trim();
            }
          } else {
            result.code = 400;
            result.description = 'FORMATO INVALIDO';
          }
        }
      });
      if (result.code === 200) {
        const queryBase = await this.callCreateQuery(parameters, result);
        if (queryBase) {
          result.queryObjects = this.utility.join(baseType, queryBase.queryObjects);
          result.queryObjects.forEach((obj) => {
            obj.properties = {};
            if (Array.isArray(obj.baseProperties)) {
              obj.baseProperties.forEach((prop) => {
                obj.properties[prop.name] = prop.value;
              });
            }
            if (Array.isArray(obj.extendedData)) {
              obj.extendedData.forEach((props) => {
                if (Array.isArray(props?.properties)) {
                  let container = obj.properties;
                  switch (props.srcName) {
                    case 'INE 2020':
                    case 'INE 2018':
                      if (!obj.properties.ine) {
                        obj.properties.ine = {};
                      }
                      container = obj.properties.ine;
                      break;
                    case 'LICENCIAS EDOMEX':
                    case 'LICENCIAS NAYARIT':
                    case 'LICENCIAS HIDALGO':
                      if (!Array.isArray(obj.properties.lics)) {
                        obj.properties.lics = [];
                      }
                      obj.properties.lics.push({});
                      container = obj.properties.lics[obj.properties.lics.length - 1];
                      break;
                    case 'IMSS 2022':
                    case 'IMSS 2023':
                      if (!Array.isArray(obj.properties.imss)) {
                        obj.properties.imss = [];
                      }
                      obj.properties.imss.push({});
                      container = obj.properties.imss[obj.properties.imss.length - 1];
                      break;
                    case 'MANDAMIENTOS EDOMEX':
                      if (!Array.isArray(obj.properties.mandamientos)) {
                        obj.properties.mandamientos = [];
                      }
                      obj.properties.mandamientos.push({});
                      container = obj.properties.mandamientos[obj.properties.mandamientos.length - 1];
                      break;
                    case 'ISSSTE 2023':
                      if (!Array.isArray(obj.properties.issste)) {
                        obj.properties.issste = [];
                      }
                      obj.properties.issste.push({});
                      container = obj.properties.issste[obj.properties.issste.length - 1];
                      break;
                    case 'RNP':
                      if (!Array.isArray(obj.properties.rnp)) {
                        obj.properties.rnp = [];
                      }
                      obj.properties.rnp.push({});
                      container = obj.properties.rnp[obj.properties.rnp.length - 1];
                      break;
                    case 'ACTAS NACIMIENTO SINALOA':
                      if (!Array.isArray(obj.properties.nacimientosSinaloa)) {
                        obj.properties.nacimientosSinaloa = [];
                      }
                      obj.properties.nacimientosSinaloa.push({});
                      container = obj.properties.nacimientosSinaloa[obj.properties.nacimientosSinaloa.length - 1];
                      break;
                    case 'NACIMIENTO EDOMEX':
                      if (!Array.isArray(obj.properties.nacimientosEdomex)) {
                        obj.properties.nacimientosEdomex = [];
                      }
                      obj.properties.nacimientosEdomex.push({});
                      container = obj.properties.nacimientosEdomex[obj.properties.nacimientosEdomex.length - 1];
                      break;
                    case 'HIJOS REGISTRADOS EN SINALOA':
                      if (!Array.isArray(obj.properties.hijosSinaloa)) {
                        obj.properties.hijosSinaloa = [];
                      }
                      obj.properties.hijosSinaloa.push({});
                      container = obj.properties.hijosSinaloa[obj.properties.hijosSinaloa.length - 1];
                      break;
                    case 'HIJOS EDOMEX':
                      if (!Array.isArray(obj.properties.hijosEdomex)) {
                        obj.properties.hijosEdomex = [];
                      }
                      obj.properties.hijosEdomex.push({});
                      container = obj.properties.hijosEdomex[obj.properties.hijosEdomex.length - 1];
                      break;
                    case 'ACTAS MATRIMONIO SINALOA':
                      if (!Array.isArray(obj.properties.matrimoniosSinaloa)) {
                        obj.properties.matrimoniosSinaloa = [];
                      }
                      obj.properties.matrimoniosSinaloa.push({});
                      container = obj.properties.matrimoniosSinaloa[obj.properties.matrimoniosSinaloa.length - 1];
                      break;
                    case 'MATRIMONIOS EDOMEX':
                      if (!Array.isArray(obj.properties.matrimoniosEdomex)) {
                        obj.properties.matrimoniosEdomex = [];
                      }
                      obj.properties.matrimoniosEdomex.push({});
                      container = obj.properties.matrimoniosEdomex[obj.properties.matrimoniosEdomex.length - 1];
                      break;
                    case 'DIVORCIO EDOMEX':
                      if (!Array.isArray(obj.properties.divorcios)) {
                        obj.properties.divorcios = [];
                      }
                      obj.properties.divorcios.push({});
                      container = obj.properties.divorcios[obj.properties.divorcios.length - 1];
                      break;
                    case 'INFORMACION PENITENCIARIA EDOMEX':
                      if (!Array.isArray(obj.properties.penitenciaria)) {
                        obj.properties.penitenciaria = [];
                      }
                      obj.properties.penitenciaria.push({});
                      container = obj.properties.penitenciaria[obj.properties.penitenciaria.length - 1];
                      break;
                    case 'RENILO':
                      if (!Array.isArray(obj.properties.renilo)) {
                        obj.properties.renilo = [];
                      }
                      obj.properties.renilo.push({});
                      container = obj.properties.renilo[obj.properties.renilo.length - 1];
                      break;
                    case 'VEHICULOS POR PERSONA EDOMEX':
                      if (!Array.isArray(obj.properties.vehiculos)) {
                        obj.properties.vehiculos = [];
                      }
                      obj.properties.vehiculos.push({});
                      container = obj.properties.vehiculos[obj.properties.vehiculos.length - 1];
                      break;
                    case 'CREDENCIALIZACION SS EDOMEX':
                      if (!Array.isArray(obj.properties.credencializacion)) {
                        obj.properties.credencializacion = [];
                      }
                      obj.properties.credencializacion.push({});
                      container = obj.properties.credencializacion[obj.properties.credencializacion.length - 1];
                      break;
                    default:
                      container = obj.properties;
                  }
                  props.properties.forEach((prop) => {
                    container[prop.name] = prop.value;
                    if (prop.type === 'Photo' && prop.value && !obj?.properties?.foto) {
                      obj.properties.foto = prop.value;
                    }
                  });
                }
              });
            }
          });
        }
      }
    }
    return result;
  }
}
