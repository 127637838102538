<template>
  <transition name="slide-fade">
    <div class="map-actions">
      <div style="display: inline-flex;">
        <v-btn-toggle
          v-model="toggleExclusive"
          mandatory
          dense
          class=""
          v-hotkey="keymap"
          v-show="show"
          dark
        >
          <v-btn
            x-large
            @click="setRoadMap"
          >
            Mapa
          </v-btn>
          <v-btn
            x-large
            @click="setSatellite"
          >
            Satélite
          </v-btn>
        </v-btn-toggle>
        <div class="clock-weather">
          <clock></clock>
          <weather :user="getUser"></weather>
        </div>
      </div>
      <div style="display: inline-flex; margin-top: 5px;">
        <v-btn-toggle
          v-model="toggleDark"
          mandatory
          dense
          v-hotkey="keymap"
          dark
          v-show="!toggleExclusive"
        >
          <v-btn
            dark
            @click="setDayMode"
          >
            <v-icon>mdi-weather-sunny</v-icon>
          </v-btn>
          <v-btn
            dark
            @click="setDarkMode"
          >
            <v-icon>mdi-weather-night</v-icon>
          </v-btn>
        </v-btn-toggle>
        <div class="user-info">
          <div class="user-name" v-if="getUser">
            {{ getUser.name }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Clock from './leftPanel/Clock.vue'
import Weather from './leftPanel/Weather.vue'

export default {
  components: {
    clock: Clock,
    weather: Weather
  },
  props: {
    setSatellite: Function,
    setRoadMap: Function,
    setDarkMode: Function,
    setDayMode: Function
  },
  data() {
    return {
      show: true,
      getMapMode: 'day',
      toggleExclusive: undefined,
      toggleDark: undefined
    }
  },
  methods: {
    ...mapActions('map', ['setMapMode']),
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    keymap() {
      return {
        'ctrl+space': this.toggle,
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .map-actions {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: var(--map-widget-padding);
    top: var(--map-widget-padding);
    opacity: 0.8;
  }
  .btn-map-mode {
    border-radius: unset !important;
    width: 50% !important;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50% !important;
  }

   /* Las animaciones de entrada y salida pueden usar */
  /* funciones de espera y duración diferentes.      */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    // transform: translateY(-20px);
    opacity: 0;
  }
  .clock-weather {
    background-color: rgb(95, 99, 96);
    margin-left: 5px;
    border-radius: 5px;
    display: flex;
    // flex-direction: column;
    padding: 0px 10px 0px 10px;
    height: 54px;
  }
  .user-info {
    background-color: rgb(95, 99, 96);
    border-radius: 5px;
    display: flex;
    padding: 0px 10px 0px 10px;
    width: 313px;
    height: 36px;
    color: white;
    align-items: center;
    margin-left: 5px;
    // height: 54px;
  }
  .user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
